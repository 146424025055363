<template>
    <div id="settingLayout">
        <div class="setting-tabs">
            <div v-for="item in tabs" :key="item.path" :class="['setting-tab-item', path === item.path ? 'active' : '', item.disabled ? 'disabled' : '']" @click="handleRoute(item)">
                {{ item.title }}
            </div>
        </div>
        <keep-alive>
            <router-view></router-view>
        </keep-alive>
    </div>
</template>

<script>
    export default {
        name: 'SettingLayout',
        data () {
            return {
                tabs: [
                    { title: '个人资料', path: '/setting/user' },
                    { title: '设置登录密码', path: '/setting/password', disabled: false },
                    // { title: '消息提醒', path: '/setting/notice', disabled: true },
                    // { title: '隐私设置', path: '/setting/privacy', disabled: true },
                    // { title: '黑名单', path: '/setting/blacklist', disabled: true },
                    // { title: '股票设置', path: '/setting/st_privacy', disabled: true },
                    // { title: '应用授权', path: '/setting/oauthAuth', disabled: true },
                    // { title: '账号绑定', path: '/setting/oauthBind', disabled: true },
                    // { title: '偏好设置', path: '/setting/preferences', disabled: true },
                ],
            }
        },
        activated () {
            // console.log('activated, SettingLayout');
        },
        computed: {
            path(){
                return this.$route.path;
            }
        },
        methods: {
            handleRoute({ path, disabled }){
                if (disabled){
                    return this.$message({
                        type: 'info',
                        message: '功能暂未开放'
                    });
                }
                this.$router.push({
                    path
                })
            }
        }
    }
</script>
<style lang='less'>
    @import './index.less';
</style>